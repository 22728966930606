.UserAvatar--inner {
    margin: 0 auto;
}

#nav-menu-profile {
    padding: 16px 0 0 0;
    text-align: center;
}

#nav-menu-name {
    padding: 4px 0 0 0;
}