@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

html, body, #root {
    margin: 0;
    height: 100%;
}

#root {
    display: grid;
}

.center-spinner {
    justify-self: center;
    align-self: center;
}

button:focus {
    outline:0;
}