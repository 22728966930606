body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
    margin: 0;
    height: 100%;
}

#root {
    display: grid;
}

.center-spinner {
    justify-self: center;
    align-self: center;
}

button:focus {
    outline:0;
}
.UserAvatar--inner {
    margin: 0 auto;
}

#nav-menu-profile {
    padding: 16px 0 0 0;
    text-align: center;
}

#nav-menu-name {
    padding: 4px 0 0 0;
}
.cont {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  margin: 0 auto;
  max-width: 1200px;
}

main {
  grid-column: span 3;
  padding: 24px;
}

aside {
  grid-column: span 1;
  padding: 24px;
}

@media (max-width: 700px) {
  main,
  aside {
    grid-column: 1 / span 4;
  }
}

