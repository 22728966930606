.cont {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
  margin: 0 auto;
  max-width: 1200px;
}

main {
  grid-column: span 3;
  padding: 24px;
}

aside {
  grid-column: span 1;
  padding: 24px;
}

@media (max-width: 700px) {
  main,
  aside {
    grid-column: 1 / span 4;
  }
}
